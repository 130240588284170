const updates = [
    {
        heading: "Platform Update v2.1.0",
        caption: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore, magni.",
        body: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Temporibus unde atque fugit, illo, eaque, quod alias in nesciunt doloribus et earum deserunt aspernatur animi! Dignissimos, laborum? Illum corrupti eos dolor.",
    }
]

export default updates


import forbeslogo from "../../images/home/forbeslogo.png"
import entrepreneurlogo from "../../images/home/entrepreneurlogo.png"
import Techagersitelogo from "../../images/home/Techagersitelogo.png"
import TechBullionlogo from "../../images/home/TechBullionlogo.png"
// import nbcLogo from "../../images/home/nbcLogo.png"
import pix1 from "../../images/home/pix1.png"
import pix2 from "../../images/home/pix2.png"
import pix3 from "../../images/home/pix3.png"
import pix4 from "../../images/home/pix4.png"
import bnb from "../../images/home/bnb.png"
import subcoin from "../../images/home/subcoin.png"
import supelle from "../../images/home/supelle.png"
import animation_coin from "../../images/home/animation_coin.mp4"
import { Link } from 'react-router-dom'
import Whitepaper from '../Whitepaper/Whitepaper';
import Audit from '../SupAudit/Audit'
import React, { useState, useEffect, useMemo } from "react";
import logo from "../../images/home/logo.png"

import usdt from "../../images/home/usdt.png"
import greenDollar from '../../images/home/greenDollar.png'
import eth from '../../images/home/eth.png'
import iconBlue from "../../images/home/iconBlue.png"
import { Button, Hero, Section } from '../../Utilities'
// Import Swiper React components
import Email from '../../components/Email'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import { formatTimestampToDateString } from '../../common/methods'
import {
    useAccount,
    useNetwork,
    useWalletClient,
    useContractRead,
    useSwitchNetwork,
    useConnect
} from 'wagmi';
import { formatUnits, parseUnits, parseEther, formatEther } from "viem";
import { readContract } from '@wagmi/core'
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDebounce } from 'use-debounce';
import TokenABI from "../../chain_interaction/SupCoin.json";
import PresalePlatformABI from "../../chain_interaction/PresalePlatform.json";
import { stripeApi } from "../../services/stripe";
import { bnbApi } from "../../services/bnb";
import { mainnet, goerli, bsc, bscTestnet } from 'wagmi/chains';

import Incentive from "../../images/home/Incentive.png"
import Utility from "../../images/home/Utility.png"
import Price from "../../images/home/Price.png"
// import virtualVideo from "../../images/home/virtualVideo.mp4"
import airdropImage from "../../images/home/airdropImage.png"
import fiver from "../../images/home/fiver.png"
import freelancer from "../../images/home/freelancer.webp"
import upwork from "../../images/home/upwork.svg"
import supellLogo from "../../images/home/supellLogo.png"

import tokenomicsImg from "../../images/home/tokenomicsImg.png"
import vicon1 from "../../images/home/vicon1.png"
import vicon2 from "../../images/home/vicon2.png"

// Import Swiper styles
import 'swiper/css';
import "./Home.css"
import { useDispatch, useSelector } from 'react-redux'
import { confirmTransactionReceipt, confirmTransactionReceiptBSC } from '../../chain_interaction/client';
import { socket } from "../../App";
import { setBNBPrice, setETHPrice, setUSDTPrice } from '../../redux-toolkit/reducers/Prices'
import axios from "axios";
import { MARKET_API_KEY } from '../../utils'

import { Transak } from '@transak/transak-sdk';
import { IoCardOutline } from 'react-icons/io5'
import { Web3Button } from '@web3modal/react'
import { FaChevronRight } from "react-icons/fa"

const env = {
    staging: "930c2932-d82b-4460-8bba-e5eb1a4db68e",
    production: "01f54ed4-68c6-4100-9cf0-e92aef036dfe"
}

const transakConfig = {
    apiKey: env.staging, // (Required)
    environment: Transak.ENVIRONMENTS.STAGING, // (Required)
    // .....
    // For the full list of customisation options check the link below
};

const buyModes = ["byETH", "byUSDT", "byCard", "byBNB"];
const definedPresalePrices = [0.45, 0.55, 0.6, 0.75, 0.8];

function Home() {
    const dispatch = useDispatch();
    const { isLoading: isSwitchingLoading, switchNetwork } =
        useSwitchNetwork()

    const ethPrice = useSelector(state => state.price.ethPrice || 0);
    const usdtPrice = useSelector(state => state.price.usdtPrice || 0);
    const bnbPrice = useSelector(state => state.price.bnbPrice || 0);

    // eslint-disable-next-line
    const [maxAmountOfPhase, setMaxAmountOfPhase] = useState(0);
    const [soldAmountOfPhase, setSoldAmountOfPhase] = useState(0);
    const [startTime, setStartTime] = useState(process.env.REACT_APP_SUPCOIN_PRESALE_START_DATE);
    const [endTime, setEndTime] = useState(process.env.REACT_APP_SUPCOIN_PRESALE_END_DATE);
    const [buyMode, setBuyMode] = useState(buyModes[0]);
    const [countdown, setCountDown] = useState(0);
    const [inputAmount, setInputAmount] = useState(0);
    const [outputAmount, setOutputAmount] = useState(0);
    const [debouncedInputAmount] = useDebounce(inputAmount, 100);
    const { address, isConnected } = useAccount();
    const { data: walletClient } = useWalletClient();
    const { chain } = useNetwork();
    const [working, setWorking] = useState(false);
    const [targetDate, setTargetDate] = useState(new Date(process.env.REACT_APP_SUPCOIN_PRESALE_END_DATE * 1000));
    const [approvingTxHash, setApprovingTxHash] = useState("");
    const [presaleTxHash, setPresaleTxHash] = useState("");
    const [bnbTxHash, setBnbTxHash] = useState("");
    const [presalePriceOfPhase, setPresalePriceOfPhase] = useState(0);
    const [minPerWalletOfPhase, setMinPerWalletOfPhase] = useState(0);
    const [maxPerWalletOfPhase, setMaxPerWalletOfPhase] = useState(0);
    const [paidBnbAmount, setPaidBnbAmount] = useState(0);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect()

    useEffect(() => {
        console.log("connectors >>> ", connectors)
        let conn = connectors?.find(item => item.id === "injected");
        if (conn === undefined || conn === null) {
            if (connectors[0] !== undefined && connectors[0] !== null) {
                console.log("conn >>> ", connectors[0])

                setSelectedConnector(connectors[0]);
            }
        } else {
            console.log("conn >>> ", conn)
            setSelectedConnector(conn);
        }
    }, [connectors])

    const readPrices = () => {
        axios.get('https://api.coinranking.com/v2/coins', {
            headers: {
                'x-access-token': MARKET_API_KEY,
            },
        })
            .then(response => {
                // Handle the API response data here
                const { coins } = response.data.data;
                const ethPrice = coins.find(item => item["symbol"] === "ETH").price;
                const usdtPrice = coins.find(item => item["symbol"] === "USDT").price;
                const bnbPrice = coins.find(item => item["symbol"] === "BNB").price;
                console.log(ethPrice, usdtPrice, bnbPrice);
                dispatch(setETHPrice(ethPrice));
                dispatch(setUSDTPrice(usdtPrice));
                dispatch(setBNBPrice(bnbPrice));
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle errors here
            });
    }

    useEffect(() => {
        let readInterval = setTimeout(() => {
            readPrices();
        }, 10000);
        return () => {
            if (readInterval) clearInterval(readInterval);
        }
    }, [])

    useEffect(() => {

        socket.on("ServerTime", (data) => {
            // console.log("Sever time >>> ", data);
        })
        socket.on("SupCoinPaid", (data) => {
            console.log("Got SupCoinPaid event >>> ", data);
            if (data && data.wallet && data.wallet?.toString()?.toLowerCase() === address?.toString()?.toLowerCase()) {
                toast.success(`Please check your wallet on Goerli network. You've received ${data?.amount} Supcoin.`);
            }
        });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            const timeRemaining = getTimeRemaining(targetDate);
            setCountDown(timeRemaining);
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    //Trigger transakt
    const handleOpenTransak = () => {
        const transak = new Transak(transakConfig);
        transak.init();


        // To get all the events
        Transak.on('*', (data) => {
            console.log(data);
        });

        // This will trigger when the user closed the widget
        Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
            console.log('Transak SDK closed!');
        });

        /*
        * This will trigger when the user has confirmed the order
        * This doesn't guarantee that payment has completed in all scenarios
        * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
        */
        Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, (orderData) => {
            console.log(orderData);
        });

        /*
        * This will trigger when the user marks payment is made
        * You can close/navigate away at this event
        */
        Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
            console.log(orderData);
            transak.close();
        });
    }

    const getTimeRemaining = (targetDate) => {
        const now = new Date();
        const timeDifference = targetDate.getTime() - now.getTime();

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    useEffect(() => {

        const supAmount = buyMode === "byETH" ?
            debouncedInputAmount * ethPrice / parseFloat(presalePriceOfPhase || (definedPresalePrices[0] / 100)) :
            buyMode === "byUSDT" ?
                debouncedInputAmount * usdtPrice / parseFloat(presalePriceOfPhase || (definedPresalePrices[0] / 100))
                :
                buyMode === "byBNB" ?
                    debouncedInputAmount * bnbPrice / parseFloat(presalePriceOfPhase || (definedPresalePrices[0] / 100))
                    :
                    Math.floor(debouncedInputAmount / parseFloat(presalePriceOfPhase || (definedPresalePrices[0] / 100)));

        if (buyMode === "byCard" && supAmount % 100 > 0) {
            let q = supAmount / 100;
            q = Math.ceil(q);
            q = q * 100;
            setOutputAmount(q);
        } else {
            setOutputAmount(supAmount);
        }
        // eslint-disable-next-line
    }, [debouncedInputAmount]);

    const { data: currentPhaseIndex } = useContractRead({
        address: process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS,
        abi: PresalePlatformABI,
        functionName: 'activePhase',
        enabled: true,
        watch: true,
        chainId: 5
    });

    const { data: activePhaseStatus } = useContractRead({
        address: process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS,
        abi: PresalePlatformABI,
        functionName: 'phases',
        enabled: true,
        args: [currentPhaseIndex],
        watch: true,
        chainId: 5
    })

    const { data: userPaidUSDT } = useContractRead({
        address: process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS,
        abi: PresalePlatformABI,
        functionName: 'getUserPaidUSDT',
        args: [address, currentPhaseIndex],
        enabled: true,
        watch: true,
        chainId: 5
    });

    useEffect(() => {
        if (!activePhaseStatus) return;
        setMaxAmountOfPhase(formatEther(activePhaseStatus[0]?.toString()));
        setSoldAmountOfPhase(formatEther(activePhaseStatus[6]?.toString()));
        setStartTime(activePhaseStatus[4]);
        setTargetDate(new Date(parseInt(activePhaseStatus[5]) * 1000));
        setEndTime(activePhaseStatus[5]);
        setPresalePriceOfPhase(formatUnits(activePhaseStatus[1]?.toString(), 6));
        setMaxPerWalletOfPhase(formatUnits(activePhaseStatus[3]?.toString(), 6));
        setMinPerWalletOfPhase(formatUnits(activePhaseStatus[2]?.toString(), 6));
    }, [activePhaseStatus]);

    const onClickBuy = async () => {
        if (isConnected !== true) {

            toast.warning("Connect your wallet!");
            return;
        }
        if (outputAmount <= 0) {
            toast.warning("Invalid amount!");
            return;
        }
        try {
            if (buyMode === buyModes[0]) {
                if (chain.id !== 5) {
                    toast.warning("This platform works on Goerli network for ETH payment. Please change the network of your wallet into Goerli and try again. ");
                    return;
                }
                if (parseFloat(debouncedInputAmount * parseFloat(ethPrice)) > parseFloat(maxPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, maximum is ${Number(parseFloat(ethPrice) / parseFloat(maxPerWalletOfPhase)).toFixed(2)} ETH (${parseInt(maxPerWalletOfPhase)} USDT). Please input valid amount and try again.`)
                    return;
                }
                if (parseFloat(debouncedInputAmount * parseFloat(ethPrice)) < parseFloat(minPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, minimum is ${Number(parseFloat(ethPrice) / parseFloat(minPerWalletOfPhase)).toFixed(2)} ETH (${parseInt(minPerWalletOfPhase)} USDT). Please input valid amount and try again.`)
                    return;
                }
                setWorking(true);

                const presaleHash = await walletClient.writeContract({
                    address: process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS,
                    abi: PresalePlatformABI,
                    functionName: 'buyTokensWithETH',
                    value: parseEther(debouncedInputAmount !== undefined && debouncedInputAmount?.toString()),

                });
                setPresaleTxHash(presaleHash);
            }
            if (buyMode === buyModes[1]) {

                if (chain.id !== 5) {
                    toast.warning("This platform works on Goerli network for USDT payment. Please change the network of your wallet into Goerli and try again. ");
                    return;
                }
                console.log("debouncedInputAmount  >>> ", debouncedInputAmount);
                if (parseFloat(debouncedInputAmount) > parseFloat(maxPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, maximum is ${parseInt(maxPerWalletOfPhase)} USDT. Please input valid amount and try again.`)
                    return;
                }
                if (parseFloat(debouncedInputAmount) < parseFloat(minPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, minimum is ${parseInt(minPerWalletOfPhase)} USDT. Please input valid amount and try again.`)
                    return;
                }
                setWorking(true);
                const allowance = await readContract({
                    address: process.env.REACT_APP_USDT_ADDRESS,
                    abi: TokenABI,
                    functionName: 'allowance',
                    args: [address, process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS],

                })
                console.log(allowance, parseFloat(formatUnits(allowance !== undefined && allowance?.toString(), 6)), parseFloat(outputAmount));
                if (parseFloat(formatUnits(allowance !== undefined && allowance?.toString(), 6)) < parseFloat(outputAmount)) {

                    const aproveHash = await walletClient.writeContract({
                        address: process.env.REACT_APP_USDT_ADDRESS,
                        abi: TokenABI,
                        functionName: "approve",
                        args: [process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS, parseUnits(debouncedInputAmount !== undefined && debouncedInputAmount?.toString(), 6)], wallet: address,

                    });
                    setApprovingTxHash(aproveHash);
                }

                const presaleHash = await walletClient.writeContract({
                    address: process.env.REACT_APP_PRESALE_PLATFORM_ADDRESS,
                    abi: PresalePlatformABI,
                    functionName: 'buyTokensWithUSDT',
                    args: [parseUnits(debouncedInputAmount !== undefined && debouncedInputAmount?.toString(), 6)],

                });
                setPresaleTxHash(presaleHash);
            }
            if (buyMode === buyModes[2]) {
                if (parseFloat(debouncedInputAmount) > parseFloat(maxPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, maximum is ${parseInt(maxPerWalletOfPhase)} USD. Please input valid amount and try again.`)
                    return;
                }
                if (parseFloat(debouncedInputAmount) < parseFloat(minPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, minimum is ${parseInt(minPerWalletOfPhase)} USD. Please input valid amount and try again.`)
                    return;
                }
                stripeApi.checkout({
                    phaseIndex: parseInt(currentPhaseIndex),
                    quantity: outputAmount / 100,
                    walletAddress: address
                })
                    .then(res => {
                        window.location = res.url?.url;
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
            if (buyMode === buyModes[3]) {
                if (chain.id !== bscTestnet?.id) {
                    toast.warning("This platform works on BSC testnet network for BNB payment. Please change the network of your wallet into BSC testnet and try again. ");
                    return;
                }
                const ManagerAddress = process.env.REACT_APP_CARD_PAY_MANAGER_WALLET;
                if (parseFloat(debouncedInputAmount * parseFloat(bnbPrice)) > parseFloat(maxPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, maximum is ${Number(parseFloat(ethPrice) / parseFloat(maxPerWalletOfPhase)).toFixed(2)} BNB (${parseInt(maxPerWalletOfPhase)} USDT). Please input valid amount and try again.`)
                    return;
                }
                if (parseFloat(debouncedInputAmount * parseFloat(bnbPrice)) < parseFloat(minPerWalletOfPhase)) {
                    toast.warn(`In this phrase of presale, minimum is ${Number(parseFloat(bnbPrice) / parseFloat(minPerWalletOfPhase)).toFixed(2)} BNB (${parseInt(minPerWalletOfPhase)} USDT). Please input valid amount and try again.`)
                    return;
                }

                setWorking(true);
                const bnbHash = await walletClient.sendTransaction({
                    to: ManagerAddress, // Required except during contract publications.
                    from: address, // must match user's active address.
                    value: debouncedInputAmount ? parseEther(debouncedInputAmount?.toString()) : undefined
                });
                setPaidBnbAmount((debouncedInputAmount));
                setBnbTxHash(bnbHash);

            }
        } catch (err) {
            console.error(err);
            setWorking(false);
        }
    }

    useEffect(() => {
        ; (async () => {
            if (bnbTxHash) {
                setTimeout(async () => {
                    try {
                        const receipt = await confirmTransactionReceiptBSC(bnbTxHash, bscTestnet?.id);
                        console.log(receipt);
                        setInputAmount(0);
                        setOutputAmount(0);


                        //report bnb payment to backend
                        bnbApi.reportBnbPay({
                            transactionHash: bnbTxHash,
                            bnbAmount: paidBnbAmount,
                            walletAddress: address
                        })
                            .then(res => {
                                if (res.data?.success) {
                                    toast.success("Please check your SupCoin balance after about few mins. It may be delayed about 30 mins at max.");
                                }
                            })
                            .catch(err => {
                                console.error(err);
                            })

                        setWorking(false);

                        toast.success("You've paid BNB to buy SupCoin");

                        setBnbTxHash(null);
                        setPaidBnbAmount((0));
                    } catch (err) {
                        setWorking(false);
                        setBnbTxHash(null);
                        setPaidBnbAmount((0));
                        console.log(err);
                    }
                }, 3000);
            }
            if (approvingTxHash) {
                setTimeout(async () => {
                    try {
                        const receipt = await confirmTransactionReceipt(approvingTxHash);
                        console.log(receipt);
                        setApprovingTxHash(null);
                        toast.success("You've approved your USDT to presale contract!");
                    } catch (err) {
                        setWorking(false);
                        setApprovingTxHash(null);
                        console.log(err);
                    }
                }, 3000);
            }
            if (presaleTxHash) {
                setTimeout(async () => {
                    try {
                        const receipt = await confirmTransactionReceipt(presaleTxHash);
                        console.log(receipt);
                        setInputAmount(0);
                        setOutputAmount(0);
                        setWorking(false);
                        setPresaleTxHash(null);
                        toast.success("You've successfully bought SUP coins.");
                    } catch (err) {
                        setWorking(false);
                        setPresaleTxHash(null);
                        console.log(err);
                    }
                }, 3000);
            }
        })()
        // eslint-disable-next-line
    }, [approvingTxHash, presaleTxHash, bnbTxHash])

    const onChangeInputAmount = (value) => {

        console.log(parseFloat(value));
        setInputAmount(parseFloat(value));
    }

    const switchToChain = (targetChainId) => {
        if (isConnected !== true) {
            toast.warn("Please connect your wallet and try again.");
            return;
        }
        if (targetChainId !== chain?.id) {
            switchNetwork(targetChainId);
        }
    }

    const connectBtn = document.querySelector(`[data-testid="component-big-button"]`)
    // connectBtn.style.display = "none"


    // Tabs Controller
    const [activeTab, setActiveTab] = useState("tab1")


    return (
        <>
            <div id={"home"}>
                {/* Hero Backgrounds */}
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    autoplay={{ delay: 100000 }}
                    speed={1000}
                    loop={"true"}
                    className='w-100 h-100 position-absolute'
                >
                    <SwiperSlide className='w-100 h-100 d-none d-md-block'>
                        <div className="hero-bg hero-bg2 w-100 h-100">
                            <video autoPlay muted loop className='h-100 w-100 animation-coin'>
                                <source src={animation_coin} type="video/mp4" />
                            </video>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='w-100 h-100'>
                        <div className="hero-bg hero-bg1">
                            <div className="display"></div>
                        </div>
                    </SwiperSlide>
                </Swiper>

                <Hero centerContent={true} expand={true} className={"container-fluid border"} >
                    <div className="row  w-100 mx-auto justify-content-between align-items-center">
                        <div className="col-md-7 align-self-start mt-4">
                            <div className="hero-context text-light">
                                <h1 className='heading-sm bold'>EMBRACE AND EMBARK ON A PATH TOWARDS A PROSPEROUS OPPORTUNITY.</h1>
                                <p className='text-big bold'>Begin your journey leading the way to a brighter future
                                    with Supchain to embrace the potential of this cutting-edge
                                    digital currency. Supchain- Unleash massive financial
                                    potential with the ultimate fusion of finance and freelancing!</p>
                            </div>
                            <div className="action-btns mt-5 justify-content-start">
                                <Link onClick={() => Whitepaper()}><Button color={"light"} type={"block"}>WHITEPAPER</Button></Link>
                                <Link to={"https://github.com/thetechjd/ascendantfi/tree/main/SupChain "}><Button color={"light"} type={"inline"}>AUDIT</Button></Link>
                                <Link to="/whitelist" ><Button color={"pri"} type={"block"}>WHITELIST</Button></Link>
                            </div>
                        </div>
                        {/* <div className="col-md-5">

                            <div className="buy-section text-center text-light ml-md-auto">
                                <h6 className="bold">SECURE YOUR PURCHASE BEFORE PRICE INCREASE!</h6>
                                <h6 className="text-primary mt-3 bold">SALE {
                                    new Date().getTime() <= new Date(parseInt(startTime) * 1000).getTime() ?
                                        "STARTS" :
                                        new Date().getTime() <= new Date(parseInt(endTime) * 1000).getTime() ?
                                            "ENDS" : ""
                                } IN</h6>

                                <div className="count-down d">
                                    <div className="time">
                                        <h3 className='m-0 bold'>{countdown.days}</h3>
                                        <p className='m-0'>Days </p>
                                    </div>
                                    <div className="time bold">
                                        <h3 className='m-0 bold'>{countdown.hours}</h3>
                                        <p className='m-0'>Hours</p>
                                    </div>
                                    <div className="time">
                                        <h3 className='m-0 bold'>{countdown.minutes}</h3>
                                        <p className='m-0'>Minutes</p>
                                    </div>
                                    <div className="time">
                                        <h3 className='m-0 bold'>{countdown.seconds}</h3>
                                        <p className='m-0'>Seconds</p>
                                    </div>
                                </div>
                                <h5 className="mt-3 bold">COMING SOON</h5>

                                <div className="CARD bg-success">
                                    <div className="inner-card "
                                        style={{
                                            width: (parseInt(endTime) - new Date().getTime() / 1000 > 0) && (parseInt(startTime) < new Date().getTime() / 1000) ? Number((new Date().getTime() / 1000 - parseInt(startTime)) * 100 /
                                                (parseInt(endTime) - parseInt(startTime)))?.toFixed(2) + "%" : "0%"
                                        }}></div>
                                    <p className="m-0 CARD-text"
                                    >Until Price Increase to 1 SUPC = {definedPresalePrices[parseInt(currentPhaseIndex) + 1]} USD</p>
                                </div>

                                <h5 className="mt-3 bold">AMOUNT RAISED:  ${Number(parseFloat(soldAmountOfPhase) * parseFloat(presalePriceOfPhase))?.toFixed(2)}</h5>
                                <p className="mt-2">1 SUPC = {parseFloat(presalePriceOfPhase)} USD</p>
                                <p style={{ margin: 0, padding: 0 }} >1 ETH = {parseFloat(ethPrice)?.toFixed(4)} USD</p>
                                <p style={{ margin: 0, padding: 0 }} >1 BNB = {parseFloat(bnbPrice)?.toFixed(4)} USD</p>

                                <div className="gateway">
                                    <div className="method"
                                        style={{
                                            outline: buyMode === buyModes[0] ? "1px white solid" : "none"
                                        }}
                                        onClick={() => {
                                            setInputAmount(0);
                                            setOutputAmount(0);
                                            setBuyMode(buyModes[0]);
                                            switchToChain(goerli.id);

                                        }}
                                    >
                                        <img src={eth} alt="" className="method-img" style={{ width: "26px", height: "26px" }} />
                                        <p className="m-0 bold">ETH</p>
                                    </div>
                                    <div className="method"
                                        style={{
                                            outline: buyMode === buyModes[1] ? "1px white solid" : "none"
                                        }}
                                        onClick={() => {
                                            setInputAmount(0);
                                            setOutputAmount(0);
                                            setBuyMode(buyModes[1])
                                            switchToChain(goerli.id);

                                        }
                                        }

                                    >
                                        <img src={usdt} alt="" className="method-img" style={{ width: "24px", height: "24px" }} />
                                        <p className="m-0 bold">USDT</p>
                                    </div>
                                    <div className="method"
                                        style={{
                                            outline: buyMode === buyModes[3] ? "1px white solid" : "none"
                                        }}
                                        onClick={() => {
                                            setInputAmount(0);
                                            setOutputAmount(0);
                                            setBuyMode(buyModes[3]);
                                            switchToChain(bscTestnet?.id);
                                        }
                                        }
                                    >
                                        <img src={bnb} alt="" className="method-img" style={{ width: "24px", height: "24px" }} />
                                        <p className="m-0 bold">BNB</p>
                                    </div>
                                    <div className="method"
                                        style={{
                                            outline: buyMode === buyModes[2] ? "1px white solid" : "none"
                                        }}
                                        onClick={() => {
                                            setInputAmount(0);
                                            setOutputAmount(0);
                                            handleOpenTransak()
                                            setBuyMode(buyModes[2])
                                        }
                                        }
                                    >
                                        <IoCardOutline className="method-img" style={{ width: "24px", height: "24px" }} />
                                        <p className="m-0 bold">CARD</p>
                                    </div>
                                </div>

                                <div className="buy-form text-left"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div className="form-group">
                                        <span>Amount in {
                                            buyMode === "byETH" && "ETH"
                                        }
                                            {
                                                buyMode === "byUSDT" && "USDT"}
                                            {
                                                buyMode === "byCard" && "Card"
                                            }
                                            {
                                                buyMode === "byBNB" && "BNB"
                                            }
                                            &nbsp;you pay</span>
                                        <div className="input d-flex">
                                            <input type="number" id='other' value={inputAmount} onChange={(e) => onChangeInputAmount(e.target.value)} />
                                            {
                                                buyMode === "byETH" ?
                                                    <img src={eth} alt="" style={{ width: "20px", height: "20px", marginRight: "4px" }} />
                                                    :
                                                    buyMode === "byUSDT" ?
                                                        <img src={usdt} alt="" style={{ width: "18px", height: "18px", marginRight: "4px" }} />
                                                        :
                                                        buyMode === "byBNB" ?
                                                            <img src={bnb} style={{ width: "18px", height: "18px", marginRight: "4px" }} alt="" />
                                                            :
                                                            <img src={greenDollar} style={{ width: "26px", height: "26px", marginRight: "4px" }} alt="" />
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <span>Amount in SUPC you receive</span>
                                        <div className="input d-flex">
                                            <input type="number" value={Number(outputAmount).toFixed(2)} id='sup' disabled />
                                            <img src={iconBlue} className="method-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-primary buy-btn btn-block"
                                    onClick={() => onClickBuy()}
                                >Buy with&nbsp;
                                    {
                                        buyMode === "byETH" && "ETH"
                                    }
                                    {
                                        buyMode === "byUSDT" && "USDT"}
                                    {
                                        buyMode === "byCard" && "Card"
                                    }
                                    {
                                        buyMode === "byBNB" && "BNB"
                                    }
                                </button>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    fontSize: "14px"
                                }} >
                                    <div className="my-1 mt-2">Presale Ends {formatTimestampToDateString(process.env.REACT_APP_SUPCOIN_PRESALE_END_DATE)}</div>
                                    <div className="my-1 mt-2">You paid: {parseFloat(userPaidUSDT ? formatUnits(userPaidUSDT?.toString(), 6) : '0')?.toFixed(2)} USD</div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    fontSize: "14px"
                                }} >
                                    <div className="my-1">SUPC DEX Listing {formatTimestampToDateString(process.env.REACT_APP_SUPCOIN_DEX_LISTING_DATE)}</div>
                                    <div className="my-1 mt-2">Max per wallet: {parseFloat(maxPerWalletOfPhase)} USD</div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    fontSize: "14px"
                                }} >
                                    <div className="my-1">Listing Price 1$SUPC = {process.env.REACT_APP_SUPCOIN_LISTING_PRICE}USDT</div>
                                    <div className="my-1">Min per wallet: {parseFloat(minPerWalletOfPhase)} USD</div>
                                </div>

                            </div> 

                        </div> */}
                    </div>

                    <div className="d-flex align-items-center justify-content-center brand-container">
                        <h1 className="sub-heading text-white mt-4 mr-5">AS SEEN ON:</h1>
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <Link to="https://www.forbes.com/sites/serenitygibbons/2024/04/30/freelance-solutions-that-will-shape-tomorrows-market/?sh=6fabf3e21c33"> <img src={forbeslogo} alt="forbeslogo" className='mr-4 d-flex mt-2 brand' /></Link>
                            <Link to="https://www.entrepreneur.com/en-in/growth-strategies/the-future-of-freelancing-overcoming-challenges-with/472778"><img src={entrepreneurlogo} alt="entrepreneurlogo" className='mr-4 d-flex mt-2 brand' /></Link>
                            <Link to="https://techbullion.com/navigating-the-crypto-maze-a-guide-to-choosing-the-right-digital-currency/"><img src={TechBullionlogo} alt="TechBullionlogo" className='mr-4 d-flex mt-2 brand' /></Link>
                            <Link to="https://techager.com/the-freelancers-dilemma-inefficient-fee-structures-in-the-gig-economy/"><img src={Techagersitelogo} alt="Techagersitelogo" className='mr-4 d-flex mt-2 brandf' /></Link>
                            {/* <img src={nbcLogo} alt="nbcLogo" className='mr-3 mt-2 brand' /> */}
                        </div>
                    </div>
                </Hero>

            </div>

            {/* Welcome Section */}
            <Section name={"welcome"} mt={50} className="container" pd="20px 0 100px 0">
                <div className="text-center col-sm-8 mx-auto" data-aos="zoom-in">
                    <h1 className="heading-m pri-color">Welcome to Supchain</h1>
                    <h5 className='pri-color'>SUPCHAIN IS THE FUTURE OF GENERATONAL WEALTH</h5>
                    <h3 className='mt-5 heading-sm'>What is a Crypto Presale?</h3>
                    <p className=''>A crypto presale grants a unique opportunity to purchase ahead of others and unlock immense profits. Our presale serves as your exclusive gateway to discounted digital assets, paving the way for million-dollar returns!</p>
                    <div className="action-btns mx-auto mt-4">
                        <Link to="/about" ><Button color={"pri"} type={"block"}>Explore Our Project</Button></Link>
                        <Link to="/whitelist" ><Button color={"pri"} type={"block"}>Seed Sale Whitelist</Button></Link>
                    </div>
                </div>
            </Section>

            {/* Sup Section */}
            <Section name="subcoin" className="container" pd="50px  0">
                <div className="row">
                    <div className="col-sm-6 px-5 mt-2 d-flex justify-content-center align-items-center">
                        <div className="text-center" data-aos="fade-right">
                            <h2 className='pri-color heading-md'>What is Supchain SUPC?</h2>
                            <p className='mt-4'>Supchain is the official cryptocurrency of the Supelle Global Marketplace. Supelle and Supchain pioneer together like a married duo, synergistically complementing each other to generate value and prosperity for their forward-thinking contributors. Supchain serves as a payment system and currency that allows Supelle not just to be a global marketplace but a global decentralized marketplace. </p>
                            <div className="d-flex justify-content-center align-items-center mt-5 flex-wrap">
                                <Link to="/about#supcoin-info" className='d-block mr-4 mt-3'><Button type={"block"} color={"pri"} >What is Supchain SUPC?</Button></Link>
                                <Link to="/learn"><Button type={"block"} color={"pri"} className="mt-3 bg-gradient">Learn more about digital money</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-5 px-5" data-aos="fade-left">
                        <img src={subcoin} className='w-100' alt="" />
                    </div>
                </div>
            </Section>

            {/* Virtual */}
            <Section name="virtual">
                <div className="container h-100">
                    <div className="player" data-toggle="modal" data-target="#exampleModal"><span>Play</span></div>
                    <div className="stat">
                        <div className="stat-value">
                            <div className="stat-icon">
                                <img src={vicon1} alt="" className="img-fluid" />
                            </div>
                            <div className="stat-text">
                                <h1>10B</h1>
                                <p>Total Token Supply</p>
                            </div>
                        </div>

                        <div className="sep"></div>

                        <div className="stat-value right">
                            <div className="stat-icon">
                                <img src={vicon2} alt="" className="img-fluid" />
                            </div>
                            <div className="stat-text">
                                <h1>1.2M</h1>
                                <p>Marketcap at Listing</p>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <iframe className='w-100 h-100' src="https://www.youtube.com/embed/S1gl6vRBTKI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>


                    <div className="virtual-container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="virtual-content text-light">
                                    <div className="sub-logo">
                                        <img src={logo} className="img-fluid" alt="" />
                                    </div>
                                    <h1 className="font-weight-bold">Step into a world  <br />Beyond <span className="text-gradient">Boundaries</span>, <br /> </h1>
                                    <p> with Supchain (SUPC) the groundbreaking freelancer marketplace. Immerse yourself in transformative experiences that reshape boundaries and revolutionize the future of remote work.</p>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>

                    </div>
                </div>

            </Section>

            {/* Supelle */}
            <Section name="supelle" className={"container"} pd="80px 0 0 0">
                <div className="row">
                    <div className="col-sm-6 mt-5 align-self-end" data-aos="fade-right">
                        <img src={supelle} alt="Supelle" className="w-100" />
                    </div>
                    <div className="col-sm-6 mt-5 d-flex align-items-center">
                        <div className="mx-auto text-center" data-aos="fade-left">
                            <h2 className="pri-color heading-md">What is Supelle?</h2>
                            <h5 className=''>The People First Marketplace</h5>
                            <p className='mt-3'>Supelle is a global marketplace that serves as an umbrella. Where we provide work opportunities for freelancers and offer solution to individuals and bussiness alike. The innovative platform is the present and future of global connectivity and collaboration. We offer essential support for freelancers' success and deliver top-notch customer supports to clients.</p>
                            <div className="action-btns mx-auto mt-4 pb-4">
                                <Link to="/about#supelle-info"><Button type={"block"} color={"pri"} >What is Supelle?</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>


            {/* Tokenomics */}
            <Section name='tokenomics' pd="100px 0 0 0" className="">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mt-3">
                            <h1 className="text-center font-weight-bold">SUPCHAIN <span className="text-gradient">Tokenomics</span></h1>
                            <div className="tokenomics-img">
                                <img src={tokenomicsImg} alt="" className="img-fluid mx-auto mx-lg-0" />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                            <div className="selection-bar d-flex justify-content-center">
                                <div className={`nav-link category ${activeTab === 'tab1' && "active-tab"} mr-3`} onClick={() => setActiveTab('tab1')}>Allocation and Vesting</div>
                                <div className={`nav-link category ${activeTab === 'tab2' && "active-tab"} `} onClick={() => setActiveTab('tab2')}>Roadmap</div>
                            </div>
                            {
                                activeTab === 'tab1' && (
                                    <div className="selection-items table-responsive-sm">
                                        <table className="table">
                                            <thead className="curve-head">
                                                <tr>

                                                    <th scope="col-2" className="left-curve">Allocated to</th>
                                                    <th scope="col">%</th>
                                                    <th scope="col">Allocation</th>
                                                    <th scope="col" className="right-curve">Vesting</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Seed Round</td>
                                                    <td>2.5</td>
                                                    <td>250,000,000</td>
                                                    <td>Vesting Duration: 20 Months  (5% Unlock at Token Launch and Balance vested) <br /> Special Lock Period: None</td>
                                                </tr>
                                                <tr>

                                                    <td>Presale 1</td>
                                                    <td>7.5</td>
                                                    <td>750,000,000</td>
                                                    <td>Vesting Duration: 18 Months (10% Unlock at Token Launch/TGE and balance vested) <br /> Special Lock Period: None</td>
                                                </tr>
                                                <tr>
                                                    <td>Presaile 2</td>
                                                    <td>5</td>
                                                    <td>500,000,000</td>
                                                    <td>Vesting Duration: 15 Months (15% Unlock at Token Launch/TGE and balance vested) <br /> Special Lock Period: None</td>
                                                </tr>
                                                <tr>
                                                    <td>Public</td>

                                                    <td>8</td>
                                                    <td>800,000,000</td>
                                                    <td>Vesting Duration: 12 Months (15% Unlock at Token Launch/TGE and Balance vested) <br /> Special Lock Period: None</td>
                                                </tr>
                                                <tr>
                                                    <td>Treasury</td>
                                                    <td>5.5</td>
                                                    <td>550,000,000</td>
                                                    <td>Released as needed</td>
                                                </tr>
                                                <tr>
                                                    <td>Development</td>
                                                    <td>15</td>
                                                    <td>1,500,000,000</td>
                                                    <td>Special lock Period: NONE/ Vesting Duration: 36 months. Released according to vesting schedule</td>
                                                </tr>
                                                <tr>
                                                    <td>Ecosysyestem </td>
                                                    <td> 22.8</td>
                                                    <td>521,000,000</td>
                                                    <td>25% Unlock at Token Launch/TGE and Balance vested</td>
                                                </tr>
                                                <tr>
                                                    <td>Initial Liquidity</td>
                                                    <td> 1.5</td>
                                                    <td>150,000,000</td>
                                                    <td>Locked for 100 Years+</td>
                                                </tr>
                                                <tr>
                                                    <td>Airdrops (Treasury)</td>
                                                    <td> 0.2</td>
                                                    <td>20,000,000</td>
                                                    <td>100% Unlock at TGE</td>
                                                </tr>
                                                <tr>
                                                    <td>Team tokens </td>
                                                    <td>10</td>
                                                    <td>1,000,000,000</td>
                                                    <td>Special lock period: 6 Months, vesting duration: 25 months. Released according to vesting schedule</td>
                                                </tr>
                                                <tr>
                                                    <td>Staken rewards</td>
                                                    <td>12</td>
                                                    <td>1,200,000,000</td>
                                                    <td>Special Lock Period: NONE / Vesting Duration: 10 Months / Release Schedule: Vesting</td>
                                                </tr>
                                                <tr>
                                                    <td>Marketing</td>
                                                    <td>10</td>
                                                    <td>1,000, 000,000</td>
                                                    <td>Special lock Period: NONE/ Vesting Duration: 36 months. Released according to vesting schedule</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }

                            {
                                activeTab === 'tab2' && (
                                    <div className="selection-items">
                                        {/* Phase one */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 1: 2018 - 2019</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Freelance Platform Industry Research </p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Core team formation</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Founders brainstorm ideas for platform</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Establishment of plan and vision</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Business model validation</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Concept presentation (Mockups) (initial pitch to seed investors)</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Freelance marketplace idea conception</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Company registration</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Assembling of experts to kickstart platform building</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase two */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 2: 2020 - 2022</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Pivoted business model to adapt to the changing landscape</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Prototype creation for feasibility testing</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase three */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 3: Q1 - 2023</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Team expansion</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Infrastructure improvement</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Further platform development for better user experience</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Privately launched a BETA version for feedback and bug fixes</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Execution of feedback from testers’ feedback to further improve the platform</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase four */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 4: Q2 - 2023</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Introduction of the following features-</p>
                                                </div>
                                                <ul>
                                                    <li>Real-time messaging</li>
                                                    <li>Project tools</li>
                                                    <li>Rating system for user engagement</li>
                                                </ul>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Further improvements, expansion, and implementation of monetary strategies</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase five */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 5: Q3 - 2023</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Creation of Tokenomics</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Completion of litepaper</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Formation of social media channels</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase six */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 6: Q4 - 2023</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Kick-off Airdrop system-2 Campaign</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Creation and completion of Whitepaper</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Comprehensive Strategic marketing campaign launch</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Token Creation (supchain)</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase seven */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 7: Q1 - 2024 </h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Team Expansion</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Infrastructure improvement</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Token sale commences for seed round/investors</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Kick-off Airdrop system-3 Campaign</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Awareness marketing campaigns</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Smart contract deployment/audit</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>APrivate sale commences</p>
                                                </div>
                                                <b>Staking and Governance:</b>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Staking Smart Contracts deployed</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Governance smart contract deployed</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase eight */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 8: Q2 - 2024</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Presale (Launchpad)</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Kick-off Airdrop system-1 Campaign</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Public launch on DEX (Uniswap)</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Securing strategic partners and advisor</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>V1 marketplace Initiating</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Post-launch marketing campaigns</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Onboard crypto influencers and listing providers</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase nine */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 9: Q3 - 2024</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Securing strategic partners and advisor</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Launch Airdrop system 1</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>V2 Website Initiating</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Post-launch marketing campaigns</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>EOnboard crypto influencers and listing providers</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>DAO registration</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Initiate CEX discussions</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Listing on 1st CEX (Tier 1)</p>
                                                </div>
                                                <b>Supelle Pre-Launch Preparation:</b>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Beta Testing and Bug Bounty Program</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Community Building and Marketing</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Partnerships and Onboarding</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Phase ten */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 10: Q4 - 2024</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Token staking begins</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Team expansion continues</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Further tier 2 and tier 1 CEX listings</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>MVP app release and community reviews (Service Marketplace & Social Interactive app</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Multi-language website & support dashboard</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Growing international communities</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Major Partner announcements</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Supelle marketplace goes live</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Supelle Main-net Deployment</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>User Onboarding and Incentives</p>
                                                </div>

                                            </div>
                                        </div>
                                        {/* Phase eleven */}
                                        <div className="phase">
                                            <div className="d-flex align-items-center">
                                                <div className="box"></div>
                                                <h5 className="phase-title mb-0">Phase 11: Q1 - 2025</h5>
                                            </div>

                                            <div className="phase-items">
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Continuous Improvement and Growth</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Marketing and Adoption</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Community Engagement</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Security and Compliance</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Scaling and Global Expansion</p>
                                                </div>
                                                <div className="d-flex phase-item">
                                                    <div className="circle"></div>
                                                    <p>Decentralized Governance Implementation</p>
                                                </div>
                                            </div>
                                        </div>

                                        <h5>Notes:</h5>
                                        <ul style={{ listStyleType: "square" }}>
                                            <li>Please note that timeline and roadmap are same, but just used interchangeably..</li>
                                            <li>Olders years are summarized while current years are emphasized.</li>
                                            <li>It is not a law that everything written in the roadmap must be followed religiously, it is just a guide.</li>
                                            <li>Users are not interested in too many details put in the roadmap, proper roadmaps are designed with just bullet points.</li>
                                        </ul>

                                    </div>
                                )
                            }

                        </div>
                    </div>


                </div>

                <div className="event">
                    <div className="mini-box"></div>
                    <div className="container text-center">
                        <h1 className="font-weight-bold mini-text">Perticipate in <span className="text-gradient"> incredible <br /> event with us. </span></h1>
                    </div>
                </div>
            </Section>

            <div className="main-bg">

                <div className="mini-bg"></div>

                <div className="font">

                    {/* Supchain */}
                    <Section name={"supchain"} mt={50} className="container" pd="20px 0 100px 0">
                        <div className="text-center col-sm-8 mx-auto mb-5" data-aos="zoom-in">
                            <h3 className='mt-5 heading-sm'>Why Supchain?</h3>
                            <p className=''>Supchain is the core of Supelle platform's market setup, driving transactions in the decentralized marketplace and powering our ecosystem. Our goal is to offer a smooth, strong, and innovative experience, using blockchain technology and decentralization to transform how people collaborate and work remotely.</p>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="chain chain1">
                                        <div className="chain-img mb-4">
                                            <img src={Utility} className="img-fluid" alt="" />
                                        </div>

                                        <h6 className="mb-3 font-weight-bold">Utility</h6>

                                        <p>Supelle serves as the catalyst for unlocking the full potential of the Supelle platform, enhancing functionality and efficiency. It enables seamless decentralized marketplace transactions and strengthens the ecosystem.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="chain chain2 bd-left">
                                        <div className="chain-img mb-4">
                                            <img src={Incentive} className="img-fluid" alt="" />
                                        </div>

                                        <h6 className="mb-3 font-weight-bold">Incentives</h6>

                                        <p>Supchain holders are entitled to rewards, bonuses, discounts, or other tangible benefits. Supchain incentives are pivotal across various contexts, such as business and economics, aiding in achieving objectives more effectively.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="chain chain3 bd-left">
                                        <div className="chain-img mb-4">
                                            <img src={Price} className="img-fluid" alt="" />
                                        </div>

                                        <h6 className="mb-3 font-weight-bold">Price Appreciation</h6>

                                        <p>As the Supchain ecosystem expands, the anticipated surge in demand for Supelle is likely to drive its value upward, resulting in price appreciation.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Section>

                    {/* Access Opportunity Section */}
                    <Section name={"access"} expand={true} mt={82} pd="20px  0">
                        <div className="col-sm-8 mx-auto text-center" data-aos="fade-up">
                            <h3 className="pri-color heading-sm">Access the opportunity through an alternate route!</h3>
                            <p className="mt-3">Envision seizing a rare, once-in-a-lifetime opportunity to aquire a highly profitable assets ahead of most contributors-akin to be a part in the next Facebook or Amazon and reaping multimillion gains <br /> This is the extraordinary opportunity that awaits YOU.</p>
                        </div>

                        <div className="access-gallery container-fluid" data-aos="zoom-out">
                            <div className="row">
                                <Link className="col-sm-6 mt-5 h-100 ">
                                    <div className="gallery h-100 position-relative core relative "
                                        onClick={() => connect(selectedConnector)}
                                    >
                                        <img src={pix1} className='w-100' alt="" />
                                        <div className="px-3">
                                            <h6 className="pri-color">Connect Wallet</h6>
                                            <p>Securely connect your crypto wallet to manage and trade your digital assets easily.</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link onClick={() => Whitepaper()} className="col-sm-6 mt-5">
                                    <div className="gallery h-100">
                                        <img src={pix2} className='w-100' alt="" />
                                        <div className="px-3">
                                            <h6 className="pri-color">Whitepaper</h6>
                                            <p>Explore the intricacies and potential of our project with our comprehensive whitepaper.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="row">
                                <Link onClick={() => Audit()} className="col-sm-6 mt-5">
                                    <div className="gallery h-100">
                                        <img src={pix3} className='w-100' alt="" />
                                        <div className="px-3">
                                            <h6 className="pri-color">Audit</h6>
                                            <p>We assure complete security and reliability of our project with the rigorous audit</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/about#team" className="col-sm-6 mt-5">
                                    <div className="gallery h-100">
                                        <img src={pix4} className='w-100' alt="" />
                                        <div className="px-3">
                                            <h6 className="pri-color">Team</h6>
                                            <p>Introducing our team of seasoned professionals committed to providing you with top-notch services</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Section>

                </div>

            </div>


            {/* Airdrop */}
            <Section name="home-airdrop">
                <Link to="airdrop" style={{ textDecoration: "none" }} className="row m-0">
                    <div className="col-md-6 p-0">
                        <div className="left-air">
                            <div>


                                <button className="airbutton"> <FaChevronRight className="airicon" /> JOIN US</button>


                                <h1 className="custom-font">AIRDROP</h1>
                                <h3 className="custom-font">Available tokens =`{'>'}` 11,260,000 tokens</h3>
                                <p className="custom-font">All you need to do here is to complete quick tasks by engaging our social media  channels and get rewarded for it with Supchains.</p>
                            </div>

                            <h1 className="d-flex align-items-center custom-font"> <FaChevronRight className="airicon font-weight-light mr-3" /> JOIN TO WIN</h1>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="right-air">
                            <div className="air-img d-flex justify-content-center align-items-center">
                                <img src={airdropImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Link>

            </Section>

            {/* Compare */}
            <Section name="comparison" pd="100px 0">
                <div className="container py-2">

                    <div className="compare-box">
                        <div className="row">
                            <div className="col-md-4">
                                <h2 className="font-weight-bold"> <span style={{ color: "#ce65c3" }}> Supelle Advantage </span>: A Comparative Analysis</h2>
                            </div>
                            <div className="col-md-8  bd-left">
                                Delve into a comprehensive comparison between Supelle and other freelancing platforms, scrutinizing their pricing models and features. Supelle revolutionizes freelancer-client interactions, ensuring heightened security, absolute transparency, and cost-effectiveness. Join us as we dissect how Supelle eclipses its rivals.
                            </div>
                        </div>

                        <div className="compare-container">
                            <div className="compare">
                                <ul className="list-group first">
                                    <li className="list-group-item compare-img"><img src="img-fluid" alt="" /></li>
                                    <li className="list-group-item">Client Fee</li>
                                    <li className="list-group-item">Freelancer Fee</li>
                                    <li className="list-group-item">Withdrawal Fee <br /> <span>&nbsp;</span> </li>
                                    <li className="list-group-item">Available Funds</li>
                                    <li className="list-group-item">Decentralized - <br /> No geographical restrictions</li>
                                    <li className="list-group-item">Swift membership</li>
                                    <li className="list-group-item">NDA protects your idea</li>
                                    <li className="list-group-item">$50k Project protection</li>
                                    <li className="list-group-item">Efficient Escrow</li>
                                    <li className="list-group-item">Buying leads or Limit on Task</li>
                                    <li className="list-group-item">Scalable Dispute System</li>
                                    <li className="list-group-item">Supelle Store Credit card</li>
                                    <li className="list-group-item">Peer-to-peer loan</li>
                                    <li className="list-group-item">Supelle Auditor</li>
                                    <li className="list-group-item">Supelle Project Manager</li>
                                    <li className="list-group-item">Supelle Project Management</li>
                                    <li className="list-group-item">Staff Recruitment</li>
                                    <li className="list-group-item">Exclusive swift member perks</li>
                                    <li className="list-group-item">Supelle Community Center</li>
                                </ul>
                                <ul className="list-group text-center supchain-group">
                                    <li className="list-group-item compare-img "><img className="img-fluid" src={supellLogo} alt="" /></li>
                                    <li className="list-group-item">2%</li>
                                    <li className="list-group-item">10%</li>
                                    <li className="list-group-item">No Min Crypto/Fiat vari <br /> <span>&nbsp;</span> </li>
                                    <li className="list-group-item">Instant</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i> <br /> <span>&nbsp;</span> </li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item">Yes</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-check text-success"></i></li>
                                </ul>
                                <ul className="list-group text-center">
                                    <li className="list-group-item compare-img "><img className="img-fluid" src={freelancer} alt="" /></li>
                                    <li className="list-group-item">3%</li>
                                    <li className="list-group-item">10%</li>
                                    <li className="list-group-item">Min Payout $100 <br /> 2.75% Fee For Withdrawal</li>
                                    <li className="list-group-item">7 to 10 days</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i> <br /> <span>&nbsp;</span> </li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item">No/Partial</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                </ul>
                                <ul className="list-group text-center">
                                    <li className="list-group-item compare-img "><img className="w-100" src={upwork} style={{ maxWidth: "160px" }} alt="" /></li>
                                    <li className="list-group-item">3.5%</li>
                                    <li className="list-group-item">20%</li>
                                    <li className="list-group-item">Min Payout $100 <br /> 5% Fee For Withdrawal</li>
                                    <li className="list-group-item">7 to 10 days</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i> <br /> <span>&nbsp;</span> </li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item">No/Partial</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                </ul>
                                <ul className="list-group text-center last">
                                    <li className="list-group-item compare-img "><img className="w-100" src={fiver} style={{ maxWidth: "140px" }} alt="" /></li>
                                    <li className="list-group-item">5.5%</li>
                                    <li className="list-group-item">20%</li>
                                    <li className="list-group-item">Min Payout $100 <br /> 5% Fee For Withdrawal</li>
                                    <li className="list-group-item">7 to 10 days</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i> <br /> <span>&nbsp;</span> </li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item">No/Partial</li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                    <li className="list-group-item"><i className="fa-solid fa-circle-xmark text-light"></i></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </Section>


            {/* Opportunity Section */}
            <Section name={"opportunity"} mt={50} className="container" pd="100px  0">
                <div className="row align-items-center h-100">
                    <div className="col-md-6 mt-5 text-center" data-aos="fade-right">
                        <h2 className='heading-sm pri-color mb-5'>Abundance of Opportunities awaits</h2>
                        <div className=" mx-auto">
                            <p className=''>Be among the pioneering contributors to possess Supchain (SUPC) and witness remarkable growth in your wealth!</p>
                            <p className='mt-3'>Engage in token offerings with a trusted global marketplace, unlocking a vast network of international Opportunities.</p>
                        </div>
                    </div>
                </div>
            </Section>


            {/* Watch Video */}
            <Section name={"watch-video"} center={true} className=" text-light container">
                <div className="row justify-content-between align-items-center" data-aos="zoom-in">
                    <div className="col-md-7 mt-4">
                        <div className="video-content" data-aos="fade-up">
                            <p >SUPCHAIN TEAM PUT THIS TOGETHER FOR YOU Embrace the World of Visual Delights:</p>
                            <h3 className='heading-sm'>WATCH OUR VIDEOS</h3>
                            <p >Don't miss out on the opportunity enrich your mind and expand your horizons. Whether you have few minutes to spare or you're looking for deep dive into a specific topic, our video collection is here to serve you. Watch as experts in their fields share valuable insights, discover fascinating stories, and delve into the latest trends; a journey of discovery and enjoyment. Our videos are designed to keep you informed, enlightened, knowledgeable and inspirational. Happy Viewing!</p>
                            <Link to="support/videos" className='mx-auto d-block w-100'><Button type={"block"} color={"light"}  >Watch More</Button></Link>
                        </div>
                    </div>
                    <div className="col-md-5 my-4">
                        <div className="video">
                            <iframe className='w-100 h-100' src="https://www.youtube.com/embed/pl1yLZ00sN0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                        </div>
                    </div>
                </div>
            </Section>


            {/* New Letter */}
            <Section name={"newsletter"} className="container" pd="100px  0">
                <div className="col-sm-7 text-center" data-aos="fade-up">
                    <h2 className='heading-md'>OUR NEWSLETTER</h2>
                    <p>Sign up to our newsletter and be first to hear about Supchain news</p>

                    <Email template="template_123zbf9" serviceID="service_9xd790e">
                        <div className="d-flex justify-content-center align-items-center">
                            <input type="hidden" name="message" value="You have a new subscriber!" />
                            <input type="email" name='value' className="form-control w-75" />
                            <button className="btn btn-primary" type='submit'>Subscribe</button>
                        </div>
                    </Email>
                    <p className='mt-3'>By clicking Sign Up you're confirming that you agree with our Terms & Conditions</p>
                </div>
            </Section>






            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={working || isSwitchingLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Home
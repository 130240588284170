import React, { useEffect, useState } from 'react'
import axios from 'axios';

import { Hero, Section } from "../../Utilities"
import {
  useAccount,
  useConnect,
  useNetwork,
  useWalletClient,
} from 'wagmi';
import "./Airdrop.css";

import { FaDiscord, FaTelegram, FaTwitter } from 'react-icons/fa'
import { formatEther, parseEther } from "viem";
import { readContracts } from '@wagmi/core';
import TokenABI from "../../chain_interaction/SupCoin.json";
import AirdropABI from "../../chain_interaction/AirdropPlatform.json";
import { toast } from "react-toastify";
import { confirmTransactionReceipt, checkTxIsSucceedOrNot } from "../../chain_interaction/client";
import { setRefreshFlag } from "../../redux-toolkit/reducers/Staking";
import { useDispatch, useSelector } from "react-redux";
import Reveal from "react-awesome-reveal"
import { fadeInRight, fadeInUp } from "../../common/constants";
import { Backdrop, CircularProgress } from "@mui/material";

import PlusOneIcon from '@mui/icons-material/PlusOne';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import AccordionActions from '@mui/material/AccordionActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


function Airdrop() {
  const dispatch = useDispatch();
  const refreshFlag = useSelector(state => state.staking.refreshFlag);
  const [targetDate, setTargetDate] = useState(new Date(process.env.REACT_APP_SUPCOIN_AIRDROP_END_DATE * 1000));
  const [countdown, setCountDown] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [startTime, setStartTime] = useState(process.env.REACT_APP_SUPCOIN_AIRDROP_START_DATE);
  const [endTime, setEndTime] = useState(process.env.REACT_APP_SUPCOIN_AIRDROP_END_DATE);
  const { address, isConnected } = useAccount();
  const [supWalletBalance, setSupWalletBalance] = useState(0);
  const { data: connectionData } = useConnect();
  const { data: walletClient } = useWalletClient();
  const { chain } = useNetwork();
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [working, setWorking] = useState(false);
  const [claimingHash, setClaimingHash] = useState(null);
  const [showSuccessContent, setShowSuccessContent] = useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [userId, setUserId] = useState("");
  const [Tweet, setTweet] = useState("");

  let telegram_supcoinGroupURL = "";
  let discord_supcoinGroupURL = "";

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };
  const handleTweetChange = (event) => {
    setTweet(event.target.value);
  };

  const handleCancelClick = () => {
    setExpanded(false);
    setTweet("");
    setUserId("");
  };
// telegram supcoin group add
  const f_telegramAddUser = async (event) => {
    event.preventDefault();
    // add the user in supcoin group.
    try {
      const response = await axios.post('https://tokentrendingbot.org/api/telegramSupcoin_addUser', { userId });
      console.log("telegram server response:", response.data.inviteLink);
      telegram_supcoinGroupURL = response.data.inviteLink;
      console.log("telegram_supcoinGroupURL: ", telegram_supcoinGroupURL);
      // telegram_supcoin newtab open
      window.open(telegram_supcoinGroupURL, '_blank');
      setExpanded(false);
    } catch (error) {
      console.error('Error adding user to Telegram group:', error);
      // Handle error (show error message, etc.)
      setExpanded(false);
    }
  };
// discord supcoin server add
  const f_discordAddUser = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://tokentrendingbot.org/api/discordSupcoin_addUser', { userId });
      console.log("discord server response:", response.data.inviteLink);
      discord_supcoinGroupURL = response.data.inviteLink;
      console.log("discord_supcoinGroupURL: ", discord_supcoinGroupURL);
      window.open(discord_supcoinGroupURL, '_blank');
      setExpanded(false);
    } catch (error) {
      console.error('Error adding user to Telegram group:', error);
      // Handle error (show error message, etc.)
    }
    setExpanded(false);
    setUserId("");
  };

// follow Tweet
  const f_twitterFollow = async (event) => {
    // event.preventDefault();
    try {
      const response = await axios.post('https://tokentrendingbot.org/api/follow-tweet', { userId });
      const follow_tweetURL = response.data.followTweetUrl;
      window.open(follow_tweetURL, '_blank');
      setExpanded(false);
    } catch (error) {
      console.error('Error following tweet:', error);
      setExpanded(false);
    }
  };
// retweet Tweet
  const f_twitterRetweet = async () => {
    try {
      const response = await axios.post('https://tokentrendingbot.org/api/retweet-tweet');
      const retweet_tweetURL = response.data.retweetTweetUrl;
      window.open(retweet_tweetURL, '_blank');
      setExpanded(false);
    } catch (error) {
      console.error('Error retweet tweet:', error);
      setExpanded(false);
    }
  };

  const f_twitterTweet = async () => {
    try {
      const response = await axios.post('https://tokentrendingbot.org/api/tweet-tweet');
      const retweet_tweetURL = response.data.tweetTweetUrl;
      window.open(retweet_tweetURL, '_blank');
      setExpanded(false);
    } catch (error) {
      console.error('Error retweet tweet:', error);
      setExpanded(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const timeRemaining = getTimeRemaining(targetDate);
      setCountDown(timeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const getTimeRemaining = (targetDate) => {
    const now = new Date();
    const timeDifference = targetDate.getTime() - now.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    readWalletSupBalance();
  }, [address, isConnected])

  const readWalletSupBalance = async () => {
    try {
      if (address !== undefined && address !== null) {
        const data = await readContracts({
          contracts: [
            {
              address: process.env.REACT_APP_SUPCOIN_ADDRESS,
              abi: TokenABI,
              functionName: 'balanceOf',
              args: [address],
              wallet: address
            },
            {
              address: process.env.REACT_APP_AIRDROP_PLATFORM_ADDRESS,
              abi: AirdropABI,
              functionName: 'Whitlist',
              args: [address],
              wallet: address
            }
          ]
        })
        console.log("balance data >>> ", data);
        setSupWalletBalance(data !== undefined && formatEther(data[0]["result"]));
        setClaimableAmount(data !== undefined && formatEther(data[1]["result"]));
        setClaimAmount(data !== undefined && formatEther(data[1]["result"]));
      }
    } catch (err) {
      console.error(err);
    }
  }

  const onClickClaim = async () => {
    if (isConnected) {
      if (chain.id !== 5) {
        toast.warning("Please change the network of your wallet into Goerli and try again. SUP staking platform works on Goerli network.");
        return;
      }
    } else {
      toast.warning("Connect your wallet!");
      return;
    }
    try {
      setWorking(true);
      const claimHash = await walletClient.writeContract({
        address: process.env.REACT_APP_AIRDROP_PLATFORM_ADDRESS,
        abi: AirdropABI,
        functionName: "claim",
        args: [parseEther((claimAmount !== undefined && claimAmount?.toString()) || "0")],
        wallet: address,
      });
      setClaimingHash(claimHash);
    } catch (err) {
      setWorking(false);
      console.error(err);
    }
  }

  useEffect(() => {
    ; (async () => {
      if (claimingHash) {

        setTimeout(async () => {
          try {
            const receipt = await confirmTransactionReceipt(claimingHash);

            console.log(receipt);

            const succeed = await checkTxIsSucceedOrNot(claimingHash);
            if (succeed) {
              setShowSuccessContent(true);
              readWalletSupBalance();
              dispatch(setRefreshFlag(!refreshFlag));
              setWorking(false);
              setTimeout(() => {
                setShowSuccessContent(false);
                readWalletSupBalance();
                setClaimingHash(null);
              }, 5000);
            } else {
              setWorking(false);
              setClaimingHash(null);
            }
          } catch (err) {
            setClaimingHash(null);
            setWorking(false);
            console.log(err);
          }
        }, 3000);
      }
    })()
  }, [claimingHash])

  const RenderSuccessContents = (label, txHash) => {
    return <Reveal keyframes={fadeInUp} className='onStep' delay={0} duration={800} triggerOnce>
      <div>
        {label}
        <div className="underline text-12 ">
          <a href={`https://goerli.etherscan.io/tx/${txHash}`} target="_blank" rel="noreferrer">View on Goerli Etherscan</a>
        </div>
      </div>
    </Reveal >
  }

  return (
    <div id='airdrop'>
      <Hero className="container" height={100}  >
        <div style={{
          display: "flex ",
          justifyContent: "space-evenly"
        }}
        >
          <div className='text-light' data-aos="fade-up"
          >
            <h1 className="heading-lg bold">Airdrop</h1>
            <p className="text-big bold">Available tokens ⇒ 11,260,000 tokens<br></br>
              All you need to do here is to complete quick tasks by engaging our social media channels and get rewarded for it with supcoins.
            </p>
          </div>

          <div className="col-md-5 "
          >
            {/* <div className="buy-section text-center text-light ml-md-auto">
              <h6 className="bold">CLAIM YOUR SUPCOIN!</h6>
              <h6 className="text-primary mt-3 bold">AVAILABLE UNTIL</h6>
              <div className="count-down d">
                <div className="time">
                  <h3 className='m-0 bold'>{countdown.days}</h3>
                  <p className='m-0'>Days</p>
                </div>
                <div className="time bold">
                  <h3 className='m-0 bold'>{countdown.hours}</h3>
                  <p className='m-0'>Hours</p>
                </div>
                <div className="time">
                  <h3 className='m-0 bold'>{countdown.minutes}</h3>
                  <p className='m-0'>Minutes</p>
                </div>
                <div className="time">
                  <h3 className='m-0 bold'>{countdown.seconds}</h3>
                  <p className='m-0'>Seconds</p>
                </div>
              </div>

              <div className="CARD bg-success">
                <div className="inner-card "
                  style={{
                    width: parseInt(endTime) - new Date().getTime() / 1000 > 0 ? Number((parseInt(endTime) - new Date().getTime() / 1000) * 100 /
                      (parseInt(endTime) - parseInt(startTime)))?.toFixed(2) + "%" : "0%"

                  }}></div>
                <p className="m-0 CARD-text"
                >Until close claim</p>
              </div>

              <h5 className="mt-3 bold">SUPCOIN BALANCE:  ${Number(parseFloat(supWalletBalance) * process.env.REACT_APP_SUP2USDT_RATE)?.toFixed(2)}</h5>
              <p className="mt-2">1 SUP = {parseFloat(0.08)} USD</p>

              <div className="buy-form text-left"
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ width: "100%", border: "1px solid #fff ", paddingLeft: "5px", marginBottom: "15px" }} >
                  <span>Amount to claim</span>
                  <div className="input d-flex">
                    <input type="string" id='other' value={claimAmount} onChange={(e) => setClaimAmount(e.target.value)} />
                  </div>
                </div>
              </div>

              <button className="btn btn-primary buy-btn btn-block"
                onClick={() => onClickClaim()}
              >Claim</button>
              {claimingHash && showSuccessContent && RenderSuccessContents("Successfully claimed!", claimingHash)}
            </div> */}
          </div>
        </div>
      </Hero>

      <div className="pri-light-bg">
        <Section pd="100px 0" className="container">
          <h1 className='mb-5'>Airdrop System 2 - $90,000 Airdrop <br /> Social media campaign System</h1>

          <div className="row">
            <div className="col-md-6">
              <h3>Available tokens ⇒ 11,260,000 tokens</h3>
              <p>All you need to do here is to complete quick tasks by engaging our social media channels and get rewarded for it with supcoins.</p>
              <p>Airdrop Allocation ⇒ 11,260,000 tokens (According to the tokenomics)
                Duration ⇒ (1 - 4 weeks) {`{this can be altered to suit any marketing style}`}</p>
              <p>Goal ⇒ To help build our social media communities and create massive buzz about Supelle
              </p>
              <p>Participants (Target) ⇒ 2500 participants or more (can be increased if more tokens are allocated for airdrops)</p>
              <p>Social Media Communities (Target)⇒ Telegram, Twitter, Discord</p>
              <p>We are adopting a model that will incentivize people to carry out the following activities;
              </p>
              <ol>
                <li>Join the communities (Telegram, twitter, Discord) ONLY
                  <ul className="alph-style">
                    <li>Follow Twitter page = 120 tokens
                      <ul className="rom-style">
                        <li>Users are required to submit their twitter username/handles after this action has been fulfilled for verification</li>
                      </ul>
                    </li>
                    <li>Join Telegram group = 150 tokens
                      <ul className="rom-style">
                        <li>Users are required to submit their telegram username/handles after this action has been fulfilled for verification</li>
                      </ul>
                    </li>
                    <li>Join Telegram announcement channel = 150 tokens
                      <ul className="rom-style">
                        <li>Users are required to submit their telegram username/handles after this action has been fulfilled for verification</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>Interaction with Social media contents
                  <ul className="alph-style">
                    <li>Like Twitter pinned post = 80 tokens
                      <ul className="rom-style">
                        <li>Users are required to submit the twitter username/handles after this action has been fulfilled for verification</li>
                      </ul>
                    </li>
                    <li>Share Twitter pinned post = 80 tokens
                      <ul className="rom-style">
                        <li>Users are required to submit the twitter username/handles after this action has been fulfilled for verification</li>
                      </ul>
                    </li>
                    <li >Tweet using designated # hashtags = 100 tokens
                      <ul className="rom-style">
                        <li>Users are required to submit the tweet link after this action has been fulfilled for verification</li>
                      </ul>
                    </li>
                    <li >Join Discord Server = 50 tokens </li>
                    <li>Direct referrals = 30 tokens</li>
                  </ul>
                </li>
              </ol>
            </div>

            <div className="col-md-6">
              <div className=' p-5 h-50'>
                <div className="buy-section text-center text-light ml-md-auto">
                  <h6 className="bold">CLAIM YOUR SUPCOIN!</h6>
                  <h6 className="text-primary mt-3 bold">AVAILABLE UNTIL</h6>
                  {/* <div className="count-down d">
                    <div className="time">
                      <h3 className='m-0 bold'>{countdown.days}</h3>
                      <p className='m-0'>Days</p>
                    </div>
                    <div className="time bold">
                      <h3 className='m-0 bold'>{countdown.hours}</h3>
                      <p className='m-0'>Hours</p>
                    </div>
                    <div className="time">
                      <h3 className='m-0 bold'>{countdown.minutes}</h3>
                      <p className='m-0'>Minutes</p>
                    </div>
                    <div className="time">
                      <h3 className='m-0 bold'>{countdown.seconds}</h3>
                      <p className='m-0'>Seconds</p>
                    </div>
                  </div> */}
                  <h5 className="mt-3 bold">COMING SOON</h5>

                  <div className="CARD bg-success">
                    <div className="inner-card "
                      style={{
                        width: parseInt(endTime) - new Date().getTime() / 1000 > 0 ? Number((parseInt(endTime) - new Date().getTime() / 1000) * 100 /
                          (parseInt(endTime) - parseInt(startTime)))?.toFixed(2) + "%" : "0%"

                      }}></div>
                    <p className="m-0 CARD-text"
                    >Until close claim</p>
                  </div>

                  <h5 className="mt-3 bold">SUPCOIN BALANCE:  ${Number(parseFloat(supWalletBalance) * process.env.REACT_APP_SUP2USDT_RATE)?.toFixed(2)}</h5>
                  <p className="mt-2">1 SUP = {parseFloat(0.08)} USD</p>

                  <div className="buy-form text-left"
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ width: "100%", border: "1px solid #fff ", paddingLeft: "5px", marginBottom: "15px" }} >
                      <span>Amount to claim</span>
                      <div className="input d-flex">
                        <input type="string" id='other' value={claimAmount} onChange={(e) => setClaimAmount(e.target.value)} />
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-primary buy-btn btn-block"
                    onClick={() => onClickClaim()}
                  >Claim</button>
                  {claimingHash && showSuccessContent && RenderSuccessContents("Successfully claimed!", claimingHash)}
                </div>
              </div>

              <div className=' p-5 h-50 overflow-text'>
                <div className="buy-section text-center text-light ml-md-auto">
                  <h6 className="bold" style={{ padding: "10px" }}>JOIN SOCIAL COMMUNICATION!</h6>
                  <div className="CARD bg-success" style={{ borderRadius: "10px", marginTop: "0.5rem" }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        expandIcon={expanded === 'panel1' ? <ArrowDropUpIcon /> : <GroupAddIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FaTelegram />
                            <span style={{ paddingLeft: "10px", fontSize: "16px" }}>Join @Supellecoin on Telegram</span>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionActions style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
                        <Typography>
                          <Stack spacing={2} direction="row">
                            <Button style={{ width: "100px" }} variant="contained" onClick={f_telegramAddUser}>JOIN</Button>
                            <Button style={{ width: "100px" }} variant="outlined" onClick={handleCancelClick}>Cancel</Button>
                          </Stack>
                        </Typography>
                      </AccordionActions>
                    </Accordion>  
                  </div>
                  <div className="CARD bg-success" style={{ borderRadius: "10px", marginTop: "0.5rem" }}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        expandIcon={expanded === 'panel2' ? <ArrowDropUpIcon /> : <GroupAddIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FaDiscord />
                            <span style={{ paddingLeft: "10px", fontSize: "16px" }}>Join @Supellecoin on Discord</span>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionActions style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
                        <Typography>
                          <Stack spacing={2} direction="row">
                            <Button style={{ width: "100px" }} variant="contained" onClick={f_discordAddUser}>JOIN</Button>
                            <Button style={{ width: "100px" }} variant="outlined" onClick={handleCancelClick}>Cancel</Button>
                          </Stack>
                        </Typography>
                      </AccordionActions>
                    </Accordion>
                  </div>
                  {/* <div className="CARD bg-success" style={{ borderRadius: "10px", marginTop: "0.5rem" }}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary
                        expandIcon={expanded === 'panel3' ? <ArrowDropUpIcon /> : <ThumbUpAltIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <Typography>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FaTwitter />
                            <span style={{ paddingLeft: "10px", fontSize: "16px" }}>Follow&Retweet @Supellecoin on Twitter</span>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <TextField
                            id="demo-helper-text-aligned-no-helper"
                            label="USER ID"
                            value={userId}
                            onChange={handleUserIdChange}
                            placeholder="apoller1659"
                            style={{width: "-webkit-fill-available"}}
                          />
                        </Typography>
                      </AccordionDetails>
                      <AccordionActions style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
                        <Typography>
                          <Stack spacing={2} direction="row">
                            <Button style={{ width: "100px" }} variant="contained" onClick={f_twitterFollow}>Follow</Button>
                            <Button style={{ width: "100px" }} variant="contained" onClick={f_twitterRetweet}>Retweet</Button>
                            <Button style={{ width: "100px" }} variant="contained" onClick={f_twitterTweet}>Tweet</Button>
                            <Button style={{ width: "100px" }} variant="outlined" onClick={handleCancelClick}>Cancel</Button>
                          </Stack>
                          <div class="d-flex justify-content-center" >
                            <Button style={{ width: "100px" }} variant="outlined" onClick={handleCancelClick}>Cancel</Button>
                          </div>
                        </Typography>
                      </AccordionActions>
                    </Accordion>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={working}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  )
}

export default Airdrop
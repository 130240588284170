import React from "react"

import "./Partners.css"
import { Link } from "react-router-dom"

function Partners() {
    return (
        <div className="pri-bg">
            <div className="container">

                <div className="partners d-flex justify-content-center align-items-center ">
                    <div className="d-flex  justify-content-between align-item-center w-100">
                        <h1>Partners</h1>
                        <Link to="#"><button className="btn-block-light btn">WORK WITH US</button></Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Partners
import React, { useContext, useState } from "react"
import { useDispatch } from 'react-redux'
import { UIContext } from "../context/UIcontext"
import { setSignIn, setSignUp } from '../redux-toolkit/reducers/User'




function SignUpModal() {
    const dispatch = useDispatch()
    const { show, setClose } = useContext(UIContext)

    // Form Initial state
    const [form, setForm] = useState("signIn")

    const [passwordError, setPasswordError] = useState('')
    
    // Signin Initial state;
    const [loginForm, setLoginForm] = useState({
        username: "",
        password: ""
    })

    // Password confirmation initial state
    const [formPassword, setFormPassword] = useState({
        password1: '',
        password2: '',
    });

    // Signup intial state
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        telegramId: '',
        discordId: '',
        twitterId: '',
        password: '',
    });

    // Handle sign up changes
    const handleSignUpChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };



    // Handle password validation changes
    const handlePasswordChange = (e) => {
        const { id, value } = e.target;
        setFormPassword((prevData) => ({
            ...prevData,
            [id]: value,
        }));


        // Clear password error message when user types
        if (id === 'password1' || id === 'password2') {
            setPasswordError('');
        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation logic
        if (formPassword.password1 !== formPassword.password2) {
            setPasswordError('Passwords do not match');
            return;
        }


        // Dispatch signup details in redux store
        if (form === "signUp") {

            dispatch(setSignUp(setSignUp({ ...formData, password: formPassword.password2 })))
        }

        // Dispatch signin details i redux store
        if (form === "signIn") {
            dispatch(setSignIn(loginForm))
        }


        // Clear Form
        // ===============
        setFormData({
            name: '',
            username: '',
            email: '',
            telegramId: '',
            discordId: '',
            twitterId: '',
        })

        setFormPassword({
            password1: '',
            password2: '',
        })

        setLoginForm({
            username: "",
            password: ""
        })
        // ===============

        // Hide modal
        setClose(false)


    };


    const handleSignInForm = (e) => {
        const { id, value } = e.target;
        setLoginForm((prevData) => ({
            ...prevData,
            [id]: value,
        }));

    }




    return (
        <>
            <div className={`auth-modal ${show && "show-modal"}`}>
            <div className="modal-overlay" onClick={()=> setClose(false)}></div>
                <div className="modal-dialog">
                    <form className="modal-content" onSubmit={handleSubmit}>
                        <div className="modal-header text-center">
                            <h5 className="w-100 ">{form === "signIn" ? "Sign In" : "Sign Up"} </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            {
                                form === "signIn" ? <SignIn loginForm={loginForm} handleSignInForm={handleSignInForm} /> : <SignUp formPassword={formPassword} formData={formData} passwordError={passwordError} handleSignUpChange={handleSignUpChange} handlePasswordChange={handlePasswordChange} />
                            }

                        </div>
                        <div className="modal-footer">
                            {
                                form === "signIn"
                                    ? <p>Don't have an account? <span className="text-primary" onClick={() => setForm("signUp")}>Sign Up</span></p>
                                    : <p>Have an account? <span className="text-primary" onClick={() => setForm("signIn")}>Sign In</span></p>
                            }


                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SignUpModal


function SignUp({ passwordError, formPassword, formData, handlePasswordChange, handleSignUpChange }) {


    return (
        <div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" value={formData.name} className="form-control" id="name" onChange={handleSignUpChange} />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="username" value={formData.username}>Username</label>
                        <input type="text" className="form-control" id="username" required onChange={handleSignUpChange} />
                    </div>
                </div>
            </div>

            <div className="form-group px-3">
                <label htmlFor="email" value={formData.email}>Email </label>
                <input type="email" className="form-control" id="email" required onChange={handleSignUpChange} />
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="telegram">Telegram ID</label>
                        <input type="text" value={formData.telegramId} className="form-control" id="telegramId" onChange={handleSignUpChange} />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="discord">Discord ID</label>
                        <input type="text" value={formData.discordId} className="form-control" id="discordId" onChange={handleSignUpChange} />
                    </div>
                </div>
            </div>



            <div className="form-group px-3">
                <label htmlFor="twitter">Twitter ID</label>
                <input type="text" value={formData.twitterId} className="form-control" id="twitterId" onChange={handleSignUpChange} />

            </div>
            <div className="form-group px-3">
                <label htmlFor="password1">Password</label>
                <input type="password" value={formPassword.password1} className="form-control" id="password1" required onChange={handlePasswordChange} />

            </div>
            <div className="form-group px-3">
                <label htmlFor="password2">Confirm Password </label>
                <input type="password" value={formPassword.password2} className="form-control" id="password2" required onChange={handlePasswordChange} />
                {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
            </div>
        </div>
    )
}


function SignIn({ loginForm, handleSignInForm }) {
    return (
        <div>
            <div className="form-group">
                <label htmlFor="username">Username </label>
                <input type="text" className="form-control" id="username" required value={loginForm.username} onChange={handleSignInForm} />

            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" required value={loginForm.password} onChange={handleSignInForm} />

            </div>
        </div>
    )
}
import { configureStore } from "@reduxjs/toolkit";
import PricesReducer from "./reducers/Prices";
import StakingReucer from "./reducers/Staking";
import UserReducer from "./reducers/User"

const reducer = {
  price: PricesReducer,
  staking: StakingReucer,
  user: UserReducer,
};

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});

// import React from 'react'
import SupcoinAudit from '../../store/docs/SupcoinAudit.pdf'

function Audit() {
  return window.open( SupcoinAudit, '_self', 'fullscreen=yes');
    
}

export default Audit


import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  name: "",
  username: "",
  password: "",
  email:"",
  telegramId:"",
  discordId:"",
  twitterId:"",

  
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSignIn: (state, action) => {
      return { ...state, ...action.payload }
    },

    setSignUp: (state, action) => {
      return { ...state, ...action.payload }
    },

    
  },
});

const { reducer, actions } = userSlice;

export const {
  setSignIn,
  setSignUp,
} = actions;
export default reducer;

import IbnatAli from "../images/about/IbnatAli.png"
import JeffDorsainvil from "../images/about/JeffDorsainvil.jpg"
import KristenBragoli from "../images/about/KristenBragoli.jpeg"
import LIUBOMYRKRAICHYK from "../images/about/LIUBOMYRKRAICHYK.jpg"
import Maksym from "../images/about/Maksym.jpg"
import OlesPakliuk from "../images/about/OlesPakliuk.png"
import TANYATREMEL from "../images/about/TANYATREMEL.jpg"
import TylerGidseg from "../images/about/TylerGidseg.png"
import board from "../images/about/board.jpg"
import LamotheMildort from "../images/about/LamotheMildort.jpg"
import james from "../images/about/james.jpeg"

// Post Images
import post1 from "../images/blog/post1.jpg"
import post2 from "../images/blog/post2.jpg"
import post3 from "../images/blog/post3.jpg"
import post4 from "../images/blog/post4.jpg"


export const teamMembers = [
  {
    name: "demo1"
  },
  {
    image: IbnatAli,
    linkedin: "https://www.linkedin.com/in/ibnat-ali-78aab2188/",
    name: "IBNAT ALI",
    position: "UI/UX Designer",
    about: `Having a love for art and design, Ibnat Ali joined Supelle to pursue her role as a UI/ UX designer. Since joining Supelle, Ibnat has been approaching design thinking through strategy and collaboration. She collaborates with her team to meet user needs and establish design practices. 

        Her curiosity in design led her to discover innovative visual concepts that improve the user interface. Before joining Supelle, Ibnat has been working in Florida as a web designer to help small businesses launch websites since 2019. She also worked as a graphic designer over the last 3 years. In her free time, she works as an independent artist selling merchandise online, focusing on marketing strategy and making successful sales. Ibnat holds a BFA in Graphic Design from Florida Atlantic University in the Dorothy F. Schmidt College of Arts and Letters. She has an interest in film, history, culture, and fine art.
        `
  },
  {
    image: JeffDorsainvil,
    linkedin: "https://www.linkedin.com/in/jeff-dorsainvil-a0b2b019a/",
    name: "JEFF DORSAINVIL",
    position: "Full Stack Developer",
    about: `Jeff is a Full Stack Developer with over 4 years of experience building medium to complex applications using cutting-edge technologies such as React, HTML, CSS, Typescript, Redux, Express JS, MongoDB, and more. Familiar with Agile methodologies, Jeff has a proven track record of delivering projects on time and within budget. A lifelong learner passionate about the latest technologies and industry trends, Jeff is excited to tackle new challenges and contribute to something big. Jeff brings a lot of value to the Supelle team.`
  },
  {
    image: KristenBragoli,
    linkedin: "https://www.linkedin.com/in/kristen-bragoli-0095ba67/",
    name: "KRISTEN BRAGOLI",
    position: "CO-FOUNDER & CEO",
    about: `
        Brandishing undiscovered potential, Supelle lights the spark that ignites the future of finance and freelancing, revolutionizing how we work, transact, and succeed in a borderless digital era.
        
        Kristen Bragoli is a native of New York who subsequently relocated to Florida. She has a bachelor’s degree in Business Administration.
        
        Mrs. Bragoli is an accomplished entrepreneur who has successfully owned and managed three companies. She served as the Vice President and CEO of Real Estate Investment Brokers, a global brokerage headquartered in Florida. She played a pivotal role in facilitating the integration of front-end developers in the realm of real estate, leveraging years of professional and creative expertise. 
        
        With over 20 years of experience in customer service management, real estate investment, and the finance industry, Mrs. Bragoli brings extensive knowledge and expertise to her current role at Supelle. She has a hands-on ability to work with customers in the field, bringing numerous partnerships to life. In addition, she wields a comprehensive understanding of various technological domains and boasts a lengthy history of building successful and sustainable enterprises within the global marketplace.
        `
  },
  {
    image: Maksym,
    linkedin: "https://www.linkedin.com/in/maksym-prudnik-531a611a7/",
    name: "MAKSYM PRUDNIK",
    position: "Full Stack Developer",
    about: `Introducing Maksym Prudnik, a vital asset within the Supelle team based in Ukraine and operating under the guidance of Oles. Maksym is an enthusiastic software engineer boasting approximately 4 years of hands-on experience as a Full Stack Developer, demonstrating a robust proficiency in constructing applications on both the frontend and backend.
    Having collaborated as a member of globally distributed teams, Maksym excels in effective communication with clients, showcasing a wealth of experience in Agile-based projects and a comprehensive understanding of associated processes and practices. His mastery extends to React and Node.js, skillfully applied to the development of intricate web applications.
    With a holistic grasp of the entire software development lifecycle, Maksym stands out as a seasoned professional, well-versed in tackling diverse and complex applications. Eager for continual growth within the IT sphere, Maksym brings a dedication to excellence and a passion for advancing his skills in every project undertaken. `
  },
  {
    image: LIUBOMYRKRAICHYK,
    linkedin: "https://www.linkedin.com/in/liubomyr-kraichyk-a72348106/",
    name: "LIUBOMYR KRAICHYK",
    position: "Front-End Developer",
    about: `Meet Liubomyr Kraichyk, an integral member of the Supelle team based in Ukraine, under the leadership and management of Oles. Liubomyr stands out as a proficient Front-End Developer, contributing his expertise to the creation of dynamic websites and web applications.
    With a focused specialization in JavaScript, Liubomyr brings a wealth of professional experience, particularly excelling in the utilization of React along with Redux and other associated libraries. With a robust background in Front-End development spanning over 3 years, Liubomyr is driven by a high motivation to learn, a keen attention to detail, and a proven knack for solving complex problems in the realm of web application development. Joining forces with the Supelle team, Liubomyr is committed to pushing the boundaries of innovation and delivering exceptional user experiences through his mastery of front-end technologies.
    
        `
  },
  {
    image: OlesPakliuk,
    linkedin: "https://www.linkedin.com/in/oles-pavliuk-396078131/",
    name: "OLES PAVLIUK",
    position: "Lead Front-end Developer",
    about: `Oles has over 7 years of experience in the IT industry as a PM and Front-end developer. Having led and implemented multiple projects in various spheres of business, including entertainment, social networking, finances, crypto, education, healthcare, and others. Oles has experience in bringing projects to life from the phase of the idea up to successful market reach—a keen tech enthusiast with experience in mobile, back-end, and web development. 
    As a seasoned Project Manager with a passion for orchestrating success in the tech realm, Oles thrives in the dynamic environment of Supelle leading technology company. With a knack for seamless collaboration with the PettersonApps he oversees projects with a global touch, managing a diverse team of skilled professionals, particularly in the vibrant tech hub of Ukraine.
    Oles is adept at navigating the intricacies of cross-cultural collaboration, leveraging his leadership skills to foster innovation and efficiency. With a commitment to excellence, he ensures that projects unfold seamlessly, from conceptualization to execution, meeting and exceeding both client expectations and industry standards.
    Beyond the boardroom, he is driven by a vision of creating technological solutions that transcend borders. His role involves not only managing projects, but also nurturing a work culture that values diversity, fosters creativity, and embraces the power of global collaboration.
    Join Supelle on the journey of technological innovation and teamwork, where Boca Raton Florida meets Ukraine in a fusion of expertise, dedication, and cutting-edge solutions.
    
        `
  },
  {
    image: board,
    name: "Mr. Ramesses || Narmer",
    position: "Founder",
    about: `About the Founder.
    Unveiling the Visionary: Delve into the Fascinating Journey of the Mastermind behind Our Crypto-Powered Freelance Ecosystem!
    With over 18 years of executive experience in leading on-demand software vendors, -Mr.  Ramesses ll Narmer, the founder, and CEO of Supelle Global Tech, has brought a wealth of expertise to the table. His pivotal role in adopting technology within the global service exchange and B2B trading has been instrumental in shaping our company’s trajectory.
    In the first of his companies, he held the position of Vice President and General Manager of the On-Demand Hosting Business Unit. In this capacity, he spearheaded the development and execution of all service-related activities for the company’s inaugural release of its groundbreaking website hosting solution. He was the driving force behind the creation and seamless performance of this solution, marking a pivotal milestone for the company.
    Moving further in his exceptional career, he proceeded to become the founder and CEO of a Data and Business Solutions Consultancy. During his tenure, he gained direct experience with the expenses and intricacies of constructing on-premise analysis and reporting solutions.
    In pursuit of greater heights, he ascended to the position of founder and CEO of a multinational company, where he led the creation of a scalable systems program for Real Estate. Through his visionary leadership, he pioneered the implementation of cutting-edge, highly parallel software technology in Real Estate investment and property flipping.
    Finally, to crown all of his remarkable accomplishments over nearly 2 decades, he has embarked on building Supelle: a first-of-its-kind decentralized marketplace that will transform the future of global freelancing.
    Supelle, unlike traditional platforms, operates as a Decentralized Autonomous Organization, free from government restrictions and payment limitations. This grants freelancers and clients unmatched flexibility and freedom to collaborate worldwide, using any currency they prefer. Consequently, disadvantaged nations can finally attain a fair opportunity, leveling the global playing field and promoting workplace equality. Most importantly, Supelle’s decentralized structure guarantees full transparency and fairness for all users.
    
    A Message from Our CEO & Founder.
    Here, our mission is clear: to usher in a new era where the traditional world transforms into a thriving digital economy. We’ve begun achieving this by reshaping the freelancing and capital marketplaces to become inclusive to every person, anywhere and at any time.
    At Supelle, our commitment is to deliver exceptional freelancing services, instilling hope and empathy in every interaction. We strive to be more than just a platform; we aim to empower individuals and provide them with new and boundless opportunities for growth and success.
    Guided by our core values of Innovation, Collaboration, Accountability, Respect, and Excellence, our vision and purpose is to become a globally recognized freelancing marketplace that sets the standard for cutting-edge excellence. We are poised to revolutionize the freelancing landscape and foster the professional development of freelancers within the international communities we serve.
    Together, we will create a future where talent is nurtured, connections are forged, and remarkable achievements are realized. Join us as we embark on this transformative journey!
    -Mr.  Ramesses ll Narmer
    
        `
  },

  {
    image: TANYATREMEL,
    linkedin: "https://www.linkedin.com/in/tanya-tremel/",
    name: "TANYA TREMEL ",
    position: "Chief Marketing Officer (CMO)",
    about: `Tanya Tremel is an exceptional marketing leader with a remarkable blend of intuitive business skills and artistic prowess. With her extensive experience in design, branding, and marketing, including a specialization in higher education in marketing, Tanya has consistently demonstrated her ability to drive creative strategies and enhance processes for optimal results.
 
        As a natural collaborator, Tanya thrives on working closely with others, leveraging her people skills to bring out the best in everyone involved. Whether leading a team or contributing to a creative project, she consistently brings a unique perspective and a keen eye for detail to the table. Tanya's dedication to producing high-quality work is unwavering, and she takes immense pride in curating and delivering exceptional outcomes that truly represent her identity as an individual.
         
        Driven by her passion, Tanya's work ethic is unparalleled. She possesses a deep sense of commitment and an unwavering dedication to meeting deadlines and delivering within budgetary constraints. Tanya's leadership capabilities extend to managing a high-performance marketing team as she excels in fostering collaboration and maximizing team potential.
         
        In summary, Tanya Tremel is a passionate, driven, and talented marketing professional whose artistic background and business acumen combine to create a unique skill set. Her ability to express herself artistically, collaborate effectively with others, and produce high-quality work consistently showcases her exceptional talents. Tanya's contributions are marked by her unwavering dedication to excellence, making her an invaluable asset in any marketing endeavor.
        `
  },
  {
    image: TylerGidseg,
    linkedin: "https://www.linkedin.com/in/tyler-gidseg-671a0a175/",
    name: "TYLER GIDSEG",
    position: "Front End Developer and Graphic Designer",
    about: `Tyler Gidseg is an experienced front-end web developer and graphic designer. Driven by his passion for design, he takes pride in providing the best tailored, out-of-the-box designs to bring his own unique thumbprint to any project. Tyler got his start in creating websites using WordPress. Under Tyler’s umbrella of skills, he has learned how to best optimize websites based on best practices like SEO, Mobile responsiveness, and Domain setup. Tyler has also worked with the Walt Disney Company with Disney 2-D sketch artists, where he first discovered his innate artistic talent. It is Tyler’s hope and ambition to create the best work he can encapsulate the vision for his clients.
        `
  },
  {
    image: LamotheMildort,
    linkedin:"https://www.linkedin.com/in/lamothe-mildort-4a759767/",
    name: "Lamothe Mildort",
    position: "Senior Manager of Operations and Organizational Development",
    about: `Lamothe Mildort, a trailblazer in the ever-evolving landscape of technology, is a dynamic and results-oriented leader with a career spanning over a  decade. Mildort has established himself as a visionary leader, driving innovation and excellence in the dynamic field of technology. He consistently demonstrated his operational excellence, organizational development, and strategic leadership prowess.
    Early Career and Rise to Leadership:
    Lamothe's career commenced at a boutique consulting firm, where her analytical skills and natural leadership abilities quickly propelled him into management roles. Recognized for his ability to optimize processes and foster a positive workplace culture, he became a trusted advisor to both executives and team members.
    In 2012, Lamothe transitioned to a multinational corporation, assuming the role of Operations Manager. His strategic mindset and innovative problem-solving approach led to significant efficiency improvements, earning his successive promotions within the organization.
    Strategic Leadership at Wefreelancer Global Tech, Inc..:
    As the Senior Manager of Operations and Organizational Development at Wefreelancer Global Tech, Inc.., Lamothe plays a pivotal role in driving the company's operational strategy and nurturing a culture of continuous improvement. He oversees day-to-day operations, implements organizational development initiatives, and ensures seamless collaboration across departments.
    Under Lamothe's leadership, Wefreelancer Global Tech, Inc.. has experienced a notable increase in productivity, with a 20% reduction in operational costs due to streamlined processes and the implementation of cutting-edge technologies. His strategic vision has optimized internal functions and positioned the company as an industry leader in operational efficiency.
    Team Building and Employee Engagement:
    Mildort is passionate about fostering a positive and inclusive work environment. He has cultivated a culture where employees feel empowered to contribute their best work through targeted team-building initiatives and mentorship programs. His emphasis on professional development has resulted in high employee satisfaction and retention rates.
    Industry Recognition and Awards:
    Lamother's contributions to the field have been acknowledged with the Operational Excellence Award by the Association of Operations Professionals. This prestigious recognition reflects his commitment to setting and achieving high operational standards, thereby enhancing the overall success of Wefreelancr Global Tech, Inc.
    Thought Leadership and Community Involvement:
    Beyond his managerial responsibilities, Lamothe is an advocate for thought leadership in organizational development. 
    He is a sought-after speaker at technology industry conferences, sharing insights on effective leadership, employee engagement, and the future of work. Committed to community service, he also volunteers with organizations focused on professional development and mentorship.
    Lamothe Mildort's journey as the Senior Manager of Operations and Organizational Development is characterized by his unwavering dedication to excellence, strategic foresight, and a passion for cultivating environments where both individuals and organizations thrive in the Advanced Technologies Division is a testament to her unwavering commitment. His influence and impact extend beyond the boardroom, shaping the future of operational management and employee development in today's dynamic business landscape.
    `
  },
  {
    image: james,
    linkedin: "https://linkedin.com/in/james-jefferson-6325b8212",
    name: "Ovie J.J",
    position: "Project Manager || Web Developer ",
    about: `Ovie, a web developer and project manager with a passion for creating seamless digital experiences. Proficient in coding languages such as HTML, CSS, and JavaScript, and adept at turning design concepts into functional websites. Skilled in project management, ensuring timely delivery and effective collaboration among cross-functional teams. Committed to staying current with industry trends and technologies to enhance project outcomes and user satisfaction. `
  },
]


// Dummy Post
export const posts = [
  {
    id: 1,
    img: post1,
    title: "Blog Title One",
    text: "Some quick example text to build on the blog title and make up the bulk of the blog's content.",
    date: new Date().getMinutes()
  },
  {
    id: 2,
    img: post2,
    title: "Blog Title Two",
    text: "Some quick example text to build on the blog title and make up the bulk of the blog's content.",
    date: new Date().getMinutes()
  },
  {
    id: 3,
    img: post3,
    title: "Blog Title Three",
    text: "Some quick example text to build on the blog title and make up the bulk of the blog's content.",
    date: new Date().getMinutes()
  },
  {
    id: 4,
    img: post4,
    title: "Blog Title Four",
    text: "Some quick example text to build on the blog title and make up the bulk of the blog's content.",
    date: new Date().getMinutes()
  },
]

// vides 
export const videos = [
  {
    title: "What is money? What Would the World Be Like Without Money | Supchain",
    src: "https://www.youtube.com/embed/wFIjt8Gn2B8?si=HqSgLtkYU-OOvSrT"
  },
  {
    title: "Introduction to Digitial Currencies | Supchain",
    src: "https://www.youtube.com/embed/UDWna33uZ_U"
  },
  {
    title: "The Rise of Utility Tokens From Pizza Man to Millionaire Supchain",
    src: "https://www.youtube.com/embed/MjkY39GlpYk"
  },
  {
    title: "Empowering the Future: How Utility Tokens are Revolutionizing Professional Services",
    src: "https://www.youtube.com/embed/HDU7BX5zHQM"
  },
  {
    title: "Unlocking the Power of Utility Tokens: Revolutionizing Business Financing and",
    src: "https://www.youtube.com/embed/h5yb9eaj2JA"
  },
  {
    title: "Can Lighting Strike Twice | Supchain",
    src: "https://www.youtube.com/embed/YLE6_GCC7zw"
  },
  {
    title: "The Insiders Uncovering the Dark Secrets of Why They Succeed While We Struggle | Supchain",
    src: "https://www.youtube.com/embed/pl1yLZ00sN0"
  },
]


export const cryptos = [
  { id: 'bitcoin', symbol: 'BTC' },
  { id: 'ethereum', symbol: 'ETH' },
  { id: 'ripple', symbol: 'XRP' },
  { id: 'bitcoin-cash', symbol: 'BCH' },
  { id: 'litecoin', symbol: 'LTC' },
  { id: 'cardano', symbol: 'ADA' },
  { id: 'polkadot', symbol: 'DOT' },
  { id: 'binancecoin', symbol: 'BNB' },
  { id: 'chainlink', symbol: 'LINK' },
  { id: 'stellar', symbol: 'XLM' },
  { id: 'dogecoin', symbol: 'DOGE' },
  { id: 'tether', symbol: 'USDT' },
  { id: 'monero', symbol: 'XMR' },
  { id: 'eos', symbol: 'EOS' },
  { id: 'tron', symbol: 'TRX' },
  { id: 'dash', symbol: 'DASH' },
  { id: 'neo', symbol: 'NEO' },
  { id: 'tezos', symbol: 'XTZ' },
  { id: 'cosmos', symbol: 'ATOM' },
  { id: 'iota', symbol: 'MIOTA' }
];

export const currencies = [
  'USD', // United States Dollar
  'EUR', // Euro
  'JPY', // Japanese Yen
  'GBP', // British Pound Sterling
  'AUD', // Australian Dollar
  'CAD', // Canadian Dollar
  'CHF', // Swiss Franc
  'CNY', // Chinese Yuan
  'INR', // Indian Rupee
  'SGD', // Singapore Dollar
  'NZD', // New Zealand Dollar
  'HKD', // Hong Kong Dollar
  'SEK', // Swedish Krona
  'NOK', // Norwegian Krone
  'DKK', // Danish Krone
  'ZAR', // South African Rand
  'BRL', // Brazilian Real
  'RUB', // Russian Ruble
  'KRW', // South Korean Won
  'AED', // United Arab Emirates Dirham
];
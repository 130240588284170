// import React from 'react'
import whitepaper from '../../store/docs/whitepaper.pdf'

function Whitepaper() {
  return window.open( whitepaper, '_self', 'fullscreen=yes');
    
}

export default Whitepaper

